<template>
  <a-date-picker :value="date"
                 :get-calendar-container="(current) => current.parentNode.parentNode"
                 :allow-clear="allowClear"
                 format="YYYY-MM-DD"
                 placeholder="请选择日期"
                 class="select-date"
                 :class="{'hide-suffix': !showSuffix}"
                 @change="onChange"
                 @openChange="openChange"
                 @panelChange="panelChange">
    <template slot="suffixIcon">
      <a-icon type="down"/>
    </template>
  </a-date-picker>
</template>

<script>
import SelectDateMixin from '../../mixin/SelectDateMixin';

export default {
  name: 'SelectDate',
  mixins: [SelectDateMixin],
  props: {
    allowClear: { type: Boolean, default: false },
    showSuffix: { type: Boolean, default: true },
  },
  data() {
    return {
      type: 'date',
    };
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/select-date.scss";
</style>

<style lang="scss">
.select-date.hide-suffix {
  .ant-calendar-picker-icon {
    display: none;
  }
}
</style>
