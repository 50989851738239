<template>
  <div ref="dataCenterPageRef" class="full-page data-center-page">
    <a-layout class="data-center-page-inner">
      <Nav ref="navBar" class="nav" :style="{ transform: navTransform }" />

      <div class="full-page data-center-main">
        <router-view />
      </div>

      <BackToTop
        :refs="$refs"
        ref-name="overflowAutoRef"
        class="back-to-top flex"
      >
        <img :src="backImg" alt="" class="back-top-img" />
      </BackToTop>
    </a-layout>
    <div class="extension"></div>
  </div>
</template>

<script>
import { ossBaseUrl } from '@/Config';

import BackToTop from '../../components/layout/BackToTop.vue';
//'@/components/layout/BackToTop.vue';
import Nav from './components/layout/Nav.vue';

export default {
  name: 'Index',
  components: {
    BackToTop,
    Nav
  },
  data() {
    return {
      backImg: `${ossBaseUrl}dataCenter/buttons/back_to_top.png`,
      navTransform: 'translateX(-600px)'
    };
  },
  created() {
    this.$store.dispatch('Common/getStoreType');
    this.$store.dispatch('Common/orgtreeByUser');
  },
  mounted() {
    window.addEventListener('resize', this.resizePage, false);
  },
  methods: {
    resizePage() {
      const width = this.$refs.dataCenterPageRef?.clientWidth;
      if (!width) {
        return;
      }
      if (width >= 1200) {
        this.navTransform = 'translateX(-600px)';
      } else {
        this.navTransform = `translateX(-${width / 2}px)`;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.data-center-page {
  background: #f9fafe;
}

.data-center-page-inner {
  width: 1200px;
  min-width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding-left: 264px;
  background: transparent;
}

.nav {
  position: absolute;
  top: 75px;
  left: 50%;
  bottom: 10px;
  z-index: 99;
  transform: translateX(-600px);
}

.data-center-main {
  padding-top: 11px;
}

.back-to-top {
  position: fixed;
  right: 45%;
  transform: translateX(588px);
  width: 60px;
  height: 60px;
  bottom: 100px;
  cursor: pointer;
  z-index: 100;

  .back-top-img {
    width: 60px;
    height: 60px;
  }
}
</style>

<style lang="scss">
@import './scss/main-selected-color.scss';
</style>
