<template>
  <div class="active-statistics-day">
    <div class="title-wrapper">
      <CardTitle class="title">{{ title }}</CardTitle>
      <ButtonDownload
        :loading="loading"
        class="btn"
        @click="downloadImage(title)"
      />
      <ButtonExportData :loading="loading" class="btn" @click="exportExcel" />
    </div>

    <div class="query-1 main-selected-color-wrapper">
      <SelectRangePicker
        v-model="date"
        :format="format"
        :allow-clear="false"
        :mode="mode"
        :show-suffix="false"
        :placeholder="placeholder"
        class="select-date"
      />
      <ButtonSmall
        type="primary"
        :loading="loading"
        class="btn"
        @click="onSearch"
        >查询</ButtonSmall
      >
    </div>

    <div class="query-2">
      <ButtonSmall :loading="loading" class="btn" @click="onReset"
        >重置</ButtonSmall
      >
      <ButtonSmall :loading="loading" class="btn" @click="showHiddenModal"
        >可隐藏项</ButtonSmall
      >
    </div>

    <SingleGridLineChart
      ref="chartRef"
      v-if="originData"
      :chart-data="chartData"
      class="chart"
    />
    <a-empty v-else style="margin: 170px 0"></a-empty>

    <CheckboxModal
      ref="checkboxModalRef"
      :value="visibleIds"
      title="可隐藏项"
      :options="allVisibleItems"
      @save="visibleSave"
    />
  </div>
</template>

<script>
import moment from 'moment';

import ToggleChartVisible from '../../../../mixin/ToggleChartVisible';
import CardTitle from '../../../layout/CardTitle.vue';
import ButtonDownload from '../../../btn/ButtonDownload.vue';
import ButtonExportData from '../../../btn/ButtonExportData.vue';
import ButtonSmall from '../../../btn/ButtonSmall.vue';
import SelectRangePicker from '../../../selection/SelectRangePicker.vue';
import SingleGridLineChart from '../../../charts/SingleGridLineChart.vue';

export default {
  name: 'ActiveStatisticsDate',
  components: {
    CardTitle,
    ButtonDownload,
    ButtonExportData,
    ButtonSmall,
    SelectRangePicker,
    SingleGridLineChart
  },
  mixins: [ToggleChartVisible],
  props: {
    data: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false },
    title: { type: String, default: '' },
    type: { type: String, default: 'months' },
    format: { type: String, default: 'YYYY-MM' },
    mode: { type: Array, default: () => ['month', 'month'] },
    placeholder: { type: Array, default: () => ['开始月', '结束月'] }
  },
  watch: {
    data(val) {
      this.originData = val || {};
      this.visibleIds = this.allVisibleItems.map((i, idx) => idx + 1).join(',');
    }
  },
  data() {
    return {
      date: null
    };
  },
  created() {
    this.onReset();
  },
  methods: {
    onSearch() {
      this.$emit('search', { date: this.date, format: this.format });
    },
    onReset() {
      const sub = this.type === 'months' ? 6 : 7;
      const sub2 = 1;
      this.date = [
        moment().subtract(sub, this.type).format(this.format),
        moment().subtract(sub2, this.type).format(this.format)
      ];
      this.$emit('reset', { date: this.date, format: this.format });
    },

    exportExcel() {
      this.$emit('export-excel', { date: this.date, format: this.format });
    }
  }
};
</script>

<style scoped lang="scss">
.active-statistics-day {
  width: 330px;
}

.title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;

  & > .title {
    margin-right: 20px;
  }

  & > * {
    flex: none;
  }

  .btn {
    width: 80px;
    padding-left: 3px;
    padding-right: 3px;

    ::v-deep .btn-img {
      margin-right: 3px;
    }
  }
}

.query-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;

  .select-date {
    border-right-width: 0;
  }

  .btn {
    border-radius: 0;
  }
}
</style>
