<template>
  <Card class="position-overview">
    <span slot="title">参训率-岗位查询</span>
    <Tabs
      slot="addon"
      v-model="postType"
      @change="onSearch"
      :options="postTypes"
    />
    <div slot="extra">
      <ButtonDownload @click="downloadImage('参训率-岗位查询')" />
      <ButtonExportData @click="exportExcel" :loading="loading" />
    </div>

    <div class="query-1 main-selected-color-wrapper">
      <SelectTypedDate v-model="typedDate" />
      <SelectCourse v-model="courseIds" />
      <SelectOrg v-model="orgIds" />
      <SelectShopType
        v-model="orgParams"
        :not-all="true"
        :same-level="true"
        class="select-org"
      />
      <ButtonSmall type="primary" @click="onSearch" :loading="loading"
        >查询</ButtonSmall
      >
      <ButtonSmall @click="onReset" :loading="loading">重置</ButtonSmall>
      <ButtonSmall @click="showHiddenModal">可隐藏项</ButtonSmall>
    </div>

    <MultipleBarChart
      ref="chartRef"
      v-if="originData"
      :chart-data="chartData"
      class="chart"
    />
    <a-empty v-else style="margin: 160px 0"></a-empty>

    <CheckboxModal
      ref="checkboxModalRef"
      :value="visibleIds"
      title="可隐藏项"
      :options="allVisibleItems"
      @save="visibleSave"
    />
  </Card>
</template>

<script>
import moment from 'moment';

import ToggleChartVisible from '../../../mixin/ToggleChartVisible';

import Tabs from '../../layout/Tabs.vue';
import Card from '../../layout/Card.vue';
import ButtonDownload from '../../btn/ButtonDownload.vue';
import ButtonExportData from '../../btn/ButtonExportData.vue';
import ButtonSmall from '../../btn/ButtonSmall.vue';
import SelectTypedDate from '../../selection/SelectTypedDate';
import SelectCourse from '../../selection/SelectCourse.vue';
import SelectShopType from '../../selection/SelectShopType.vue';
import SelectOrg from '../../selection/SelectOrg.vue';
import MultipleBarChart from '../../charts/MultipleBarChart.vue';
import {
  getPostGroupParticipat,
  excelPostGroupParticipat
} from '../../../api/index';
import DateCommon from '@/utils/DateCommon';

export default {
  name: 'PositionOverview',
  components: {
    Tabs,
    Card,
    ButtonDownload,
    ButtonExportData,
    ButtonSmall,
    SelectTypedDate,
    SelectCourse,
    SelectShopType,
    SelectOrg,
    MultipleBarChart
  },
  mixins: [ToggleChartVisible],
  data() {
    return {
      typedDate: {
        type: 'month',
        date: moment().subtract(1, 'months').format('YYYY-MM')
      },
      courseIds: '',
      shopIds: [],
      orgIds: '',
      loading: false,
      postTypes: [
        { id: -1, name: '全部' },
        { id: 1, name: '销售岗位' },
        { id: 2, name: '服务岗位' }
      ],
      postType: '',
      parmas: {},
      originData: {},
      orgParams: {}
    };
  },
  mounted() {
    this.onReset();
  },
  methods: {
    onSearch() {
      if (!this.loading) {
        this.getData();
      }
    },
    onReset() {
      this.typedDate = {
        type: 'month',
        date: moment().subtract(1, 'months').format('YYYY-MM')
      };
      this.courseIds = '';
      this.shopIds = [];
      this.orgIds = '';
      if (!this.loading) {
        this.getData();
      }
    },
    async getPostGroupParticipat(params) {
      this.$refs.chartRef.showLoading();
      this.loading = true;
      const data = await getPostGroupParticipat(params).finally(() => {
        this.$refs?.chartRef?.hideLoading();
        this.loading = false;
      });
      if (!(data && data.error_code === 0)) {
        this.originData = undefined;
        return;
      }
      this.originData = {
        date: data.data.xAxis.data,
        //  series:data.data.series,
        series: [
          {
            name: '参训率',
            type: 'bar',
            data: data.data.series?.[0]?.data,
            selected: true
          }
        ],
        color: [
          {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: '#324FA1' // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#6187CF' // 100% 处的颜色
              }
            ],
            global: false // 缺省为 false
          },
          {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: '#95DAC1' // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#C7EFE2' // 100% 处的颜色
              }
            ],
            global: false // 缺省为 false
          },
          {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: '#FFDB55' // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#FFECA1' // 100% 处的颜色
              }
            ],
            global: false // 缺省为 false
          }
        ],
        title: '不同岗位参训率查询',
        yAxisName: '参训率',
        yAxisLabelFormatter: '{value}%',
        axisLabel: { interval: 0, rotate: 65 },
        zoomNum: 12,
        baseLine: data?.data?.avg_rate
      };
    },
    async getData() {
      const params = this.getParams();
      if (!params && this.loading) {
        return;
      }
      await this.getPostGroupParticipat(params);
      this.visibleIds = this.allVisibleItems.map((i, idx) => idx + 1).join(',');
    },
    exportExcel() {
      const params = this.getParams();
      if (!params) {
        return;
      }
      this.loading = true;
      excelPostGroupParticipat({
        ...params,
        is_download: 1
      }).finally(() => {
        this.loading = false;
      });
    },
    getParams() {
      console.log('getParams');
      if (!this.typedDate.date) {
        this.$message.error('请选择时间');
        return false;
      }
      this.typedDate.date = this.typedDate.date || moment().format('YYYY-MM');
      return {
        post_group: this.postType >= 0 ? this.postType.toString() : 0,
        day_type: this.typedDate.type,
        start_day: DateCommon.getMomentStart(
          this.typedDate.type,
          this.typedDate.date
        ),
        course_ids: this.courseIds.toString(),
        // org_ids: this.orgIds.toString(),
        // org_type: this.shopIds.toString()
        ...this.orgParams,
        org_ids: this.orgIds.toString()
      };
    },
    onblur(data) {
      this.shopIds = data;
    }
  }
};
</script>

<style scoped lang="scss">
.query-1 {
  white-space: nowrap;
  margin-bottom: 10px;
}

.query-1 {
  & > * {
    margin-left: 0;
    margin-right: 10px;
    vertical-align: middle;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
