import CheckboxModal from '../components/section/CheckboxModal';

const mixin = {
  components: {
    CheckboxModal,
  },
  computed: {
    allVisibleItems () {
      return [
        ...(this.originData?.date || []).map((i, idx) => ({
          id: idx + 1,
          name: i,
        })),
      ];
    },
    chartData () {
      const data = {
        ...this.originData || {},
      };
      const visibleIds = (this.visibleIds?.split(',') || []).map((i) => +i).filter((i) => !!i);
      const visibleItems = this.allVisibleItems.filter((i) => visibleIds.includes(i.id)).map((i) => i.name);

      data.date = [...(data.date || [])];
      data.series = [...(data.series || [])];
      data.series?.forEach((series, idx, arr) => {
        arr[idx] = { ...series };
        arr[idx].data = [...(series.data || [])];
      });

      for (let i = data.date.length - 1; i >= 0; i -= 1) {
        if (!visibleItems.includes(data.date[i])) {
          data.date.splice(i, 1);
          data.series.forEach((series) => series.data.splice(i, 1));
        }
      }

      return data;
    },
  },
  data () {
    return {
      originData: null,

      visibleIds: '',
    };
  },
  methods: {
    downloadImage (fileName) {
      if (this.$refs.chartRef?.saveImage) {
        this.$refs.chartRef.saveImage(fileName);
      }
    },

    showHiddenModal () {
      if (!this.allVisibleItems?.length) {
        this.$message.info('无可隐藏项');
        return;
      }
      if (this.$refs.checkboxModalRef?.show) {
        this.$refs.checkboxModalRef.show();
      }
    },
    visibleSave (evt) {
      this.visibleIds = evt;
    },
  },
};

export default mixin;
