<template>
  <div ref="chartRef" class="chart"></div>
</template>
<script>
import * as echarts from 'echarts';
import {
  setDateZoom,
  setTitle,
  saveImage,
  defaultLegend,
} from '../../assets/EChartsConfig';

export default {
  name: 'MultipleBarChart',
  props: {
    chartData: { type: Object, default: () => ({}) },
  },
  watch: {
    chartData(data) {
      this.updateData(data);
    },
  },
  data() {
    return {
      chart: null,
      aveName: '平均值AVE',
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    handleClick(evt) {
      this.$emit('x-axis-click', evt);
    },
    handleMove(params) {
      if (params.componentType === 'xAxis') {
        const elementDiv = document.querySelector('.extension');
        // 设置悬浮文本的位置以及样式
        const elementStyle = 'position: absolute;z-index: 99999;color: #fff;font-size: 12px;padding: 5px;display: inline;border-radius: 4px;background-color: #303133;box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 8px';
        elementDiv.style.cssText = elementStyle;
        elementDiv.innerHTML = params.value;
        document.querySelector('html').onmousemove = function (event) {
          // eslint-disable-next-line no-shadow
          const elementDiv = document.querySelector('.extension');
          const xx = event.pageX - 10;
          const yy = event.pageY + 15;

          elementDiv.style.top = `${yy}px`;
          elementDiv.style.left = `${xx}px`;
        };
      }
    },
    handleOut(params) {
      // 注意这里，我是以X轴显示内容过长为例，如果是y轴的话，需要改为yAxis
      if (params.componentType === 'xAxis') {
        const elementDiv = document.querySelector('.extension');

        elementDiv.style.cssText = 'display:none';
      }
    },
    initChart() {
      const ref = this.$refs.chartRef;
      if (!ref) {
        return;
      }

      this.chart = echarts.init(ref);
      this.updateData(this.chartData);
      this.chart.on('click', 'xAxis', this.handleClick);
      this.chart.on('mousemove', 'xAxis', this.handleMove);
      this.chart.on('mouseout', 'xAxis', this.handleOut);
    },

    setSeriesItem(option) {
      if (!option?.series?.length) {
        option.series = [];
      }
      option.series.forEach((i) => {
        i.emphasis = {
          focus: 'series',
        };
        i.barMaxWidth = 14;
        i.barGap = '120%';
        i.label = {
          show: true,
          formatter: '{c}%',
          fontSize: 8,
          color: '#294AA5',
          fontFamily: 'DINCondensed-Bold, DINCondensed',
          fontWeight: 'bold',
          align: 'center',
          position: 'top',
        };
      });

      // // 添加参训率均值参考线
      // if (baseLine || baseLine === 0) {
      //   const count = xAxis?.length || 0;
      //   const data = [];
      //   for (let i = 0; i < count; i += 1) {
      //     data.push({
      //       value: baseLine,
      //       label: {
      //         show: !i,
      //         formatter: '参训率均值\n' + baseLine + '%',
      //         fontSize: 10,
      //         fontFamily: 'PingFangSC-Semibold, "PingFang SC"',
      //         fontWeight: 'bold',
      //         align: 'center',
      //         position: 'top'
      //       }
      //     });
      //   }

      //   const item = {
      //     name: this.aveName,
      //     data,
      //     type: 'line',
      //     silent: true,
      //     tooltip: {
      //       show: false
      //     },
      //     symbol: 'rect',
      //     symbolSize: 1,
      //     cursor: 'default',
      //     connectNulls: true,
      //     triggerLineEvent: false,
      //     itemStyle: {
      //       normal: {
      //         label: {
      //           show: true, //开启显示
      //           position: 'top', //在上方显示
      //           textStyle: {
      //             //数值样式
      //             'font-family': 'DINCondensed-Bold, DINCondensed',
      //             color: '#FB2E1C',
      //             fontSize: 12,
      //             'font-weight': 'bold',
      //             'line-height': '14px'
      //           }
      //         }
      //       }
      //     },
      //     lineStyle: {
      //       width: 1,
      //       type: 'dashed',
      //       color: '#FB2E1C',
      //     },
      //     grid:{
      //       left:0,
      //       right:0
      //     },
      //     label: {
      //       show: true
      //     },
      //     emphasis: {
      //       scale: true,
      //       focus: 'none',
      //       label: {
      //         show: false
      //       }
      //     }
      //   };
      //   if (option.series[option.series.length - 1].name !== this.aveName) {
      //     option.series.push(item);
      //   } else {
      //     option.series[option.series.length - 1] = item;
      //   }
      // }
    },

    updateData(data) {
      if (!this.chart) {
        return;
      }
      if (this.chart?.showLoading) {
        this.chart.showLoading();
      }

      const {
        date = [],
        series = [],
        color = [],
        zoomX = true,
        zoomY = false,
        zoomNum = 0,
        showLegend = false,
        baseLine = null,
        title = '',
        yAxisName = '',
        yAxisLabelFormatter = '{value}',
      } = data || {};
      let markLine = {};
      const dataSelected = [];
      const SeriesData = [];
      if (series && showLegend) {
        series.forEach((s) => {
          if (s?.selected) {
            dataSelected[s.name] = s.selected;
            SeriesData.push(s);
          }
        });
      } else {
        SeriesData.push(...series);
      }
      markLine = this.getMark(baseLine, true);
      const option = this.getOption(
        color,
        showLegend,
        series,
        dataSelected,
        yAxisName,
        date,
        data,
        yAxisLabelFormatter,
        SeriesData,
        markLine,
      );
      setTitle(option, title);
      this.setSeriesItem(option, baseLine, date);
      setDateZoom(option, zoomX, zoomY, zoomNum, true);
      this.chart.on('legendselectchanged', (params) => {
        if (params.selected[params.name]) {
          if (params.name === '参训率') {
            markLine = this.getMark(baseLine, true);
          }
          option.legend.selected[params.name] = true;
        } else {
          if (params.name === '参训率') {
            markLine = this.getMark(0, false);
          }
          option.legend.selected[params.name] = false;
        }
        console.log('markLine', markLine);
        this.chart.setOption(option, true);
      });

      this.chart.setOption(option, true);
      this.chart.hideLoading();
    },

    saveImage(fileName) {
      saveImage(this.chart, fileName);
    },

    showLoading() {
      this.chart.showLoading();
    },
    hideLoading() {
      this.chart.hideLoading();
    },
    // eslint-disable-next-line consistent-return
    getMark(baseLine, show) {
      if (baseLine) {
        return {
          lineStyle: {
            normal: {
              opacity: show ? 1 : 0, // 线条透明度设置为0
            },
          },
          symbol: 'none', // 去掉箭头
          data: [
            {
              name: '平均值',
              yAxis: baseLine,
              symbol: 'none',
              silent: false,
              lineStyle: {
                color: '#FB2E1C',
                width: 1,
              },
              symbolSize: 1,
              label: {
                show: true,
                position: 'end',
                formatter() {
                  return `参训率均值\n${baseLine}%`;
                },
                textStyle: {
                  align: 'center',
                  fontSize: 10,
                  fontWeight: '800',
                  color: '#FB2E1C',
                },
                padding: [0, -50, 0, 0],
              },
            },
          ],
          emphasis: {
            lineStyle: {
              width: 1, // hover时的折线宽度
            },
          },
        };
      }
    },
    getOption(
      color,
      showLegend,
      series,
      dataSelected,
      yAxisName,
      date,
      data,
      yAxisLabelFormatter,
      SeriesData,
      markLine,
    ) {
      let num = 1;
      return {
        color,
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            if (!params?.length) {
              return;
            }
            const xAxisLabel = params[0].axisValueLabel;
            const items = params
              .map((i) => {
                if (i.seriesName === this.aveName) {
                  return '';
                }
                // eslint-disable-next-line no-shadow
                let color = '';
                if (i.color) {
                  color = typeof i.color !== 'string'
                    ? i.color.colorStops[0].color
                    : i.color;
                }

                return (
                  `${'<span style="display: inline-block; '
                  + 'width: 12px; '
                  + 'height: 12px; '
                  + 'border-radius: 6px; '
                  + 'margin-right: 10px; '
                  + 'background-color: '}${
                    color
                  };"></span>`
                  + `<span style="display: inline-block; width: 100px;">${
                    i.seriesName
                  }</span>`
                  + `<span style="display: inline-block; font-weight: 600;">${
                    i.value
                  }%</span>`
                  + '<br/>'
                );
              })
              .join('');
            // eslint-disable-next-line consistent-return
            return `${xAxisLabel}<br/>${items}`;
          },
          axisPointer: {
            type: 'none',
          },
        },
        grid: this.chartData.grid || {
          left: '90',
          right: '70',
          bottom: '150',
        },
        legend: {
          ...defaultLegend(),
          show: showLegend,
          data: series.map((i) => i.name).filter((i) => i !== this.aveName),
          padding: [0, 0, 70, 0],
          selected: {
            ...dataSelected,
          },
        },
        xAxis: [
          {
            type: 'category',
            triggerEvent: true,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
            data: date,
            axisLabel:
              {
                ...data?.axisLabel,
                margin: 20,
                formatter(value) {
                  if (value.length > 4) {
                    return `${value.length > 4 ? value.slice(0, 4) : value}...`;
                  }
                  return value;
                },
              } || {},
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: yAxisName || '',
            nameTextStyle: {
              fontSize: 12,
              fontWeight: 600,
              color: '#999999',
              align: 'left',
              width: 10,
            },
            min: 0,
            position: 'left',
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                type: 'dashed',
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: '#FFFFFF' },
                    { offset: 0.5, color: '#D4D8E5' },
                    { offset: 1, color: '#FFFFFF' },
                  ],
                  false,
                ),
              },
            },
            axisLabel: {
              formatter: yAxisLabelFormatter,
              margin: 20,
            },
            splitLine: {
              show: true,
              onZero: true,
              lineStyle: {
                width: 1,
                type: 'dashed',
                color: [
                  {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#FFFFFF', // 0% 处的颜色
                      },
                      {
                        offset: 0.5,
                        color: '#D4D8E5',
                      },
                      {
                        offset: 1,
                        color: '#FFFFFF', // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                ],
              },
            },
          },
        ],
        series:
          SeriesData?.map((s) => {
            if (s && num === 1) {
              num += 1;
              return {
                ...s,
                data:
                  s?.data?.map((x) => (x === '100.00' ? 100 : x)) ?? [],
                markLine,
              };
            }
            return {
              ...s,
              data:
                  s?.data?.map((x) => (x === '100.00' ? 100 : x)) ?? [],
            };
          }) || series,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.chart {
  width: 880px;
  height: 450px;
}
</style>
