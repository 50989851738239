<template>
  <Card class="active-statistics">
    <template slot="title">平台活跃统计</template>
    <div class="card-view">
      <ActiveStatisticsOverview/>
      <ActiveStatisticsDay/>
      <ActiveStatisticsMonth/>
    </div>
  </Card>
</template>

<script>
import Card from '../../../layout/Card.vue';

import ActiveStatisticsOverview from './ActiveStatisticsOverview.vue';
import ActiveStatisticsDay from './ActiveStatisticsDay.vue';
import ActiveStatisticsMonth from './ActiveStatisticsMonth.vue';

export default {
  name: 'datacenter-active-statistics',
  components: {
    Card,
    ActiveStatisticsOverview,
    ActiveStatisticsDay,
    ActiveStatisticsMonth,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
.card-view {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 898px;
  margin-top: 15px;
  padding-top: 14px;
  border-top: 1px solid #DDDDDD;
}
</style>
