<template>
  <div class="participation-analysis">
    <div class="flex space-between align-start">
      <SectionOverview class="full-height" />

      <MonthlyTrend class="full-height" />
    </div>

    <ComparisonAnalysis />
    <PositionOverview />
  </div>
</template>

<script>
import SectionOverview from './components/section/participation/SectionOverview.vue';
import MonthlyTrend from './components/section/participation/MonthlyTrend.vue';
import ComparisonAnalysis from './components/section/participation/ComparisonAnalysis.vue';
import PositionOverview from './components/section/participation/PositionOverview.vue';

export default {
  name: 'ParticipationAnalysis',
  components: {
    SectionOverview,
    MonthlyTrend,
    ComparisonAnalysis,
    PositionOverview
  }
};
</script>

<style scoped lang="scss">

</style>
