<template>
  <div class="content-analytics">
    <Card class="mt20 card">
      <template slot="title"> 内容分析 </template>
      <template slot="addon">
        <div class="addon">
          <CardTitle>
            <slot>岗位课程统计</slot>
          </CardTitle>
        </div>
      </template>
      <div slot="extra">
        <ButtonDownload @click="downloadImageNew()" />
        <ButtonExportData @click="exportExcel()" :loading="loading" />
      </div>
      <template>
        <div class="card-view">
          <div class="left">
            <TopCard
              v-for="(item, index) in dataSource"
              :key="index"
              :data-source="item"
              :class="item.class"
              class="card-view-card"
            >
              <slot>
                <p class="title">{{ item.title }}</p>
                <p class="content">{{ item.content }}</p>
              </slot>
            </TopCard>
          </div>
          <div class="right">
            <div class="bar-list-charts">
              <div class="bar-charts">
                <BarChart
                  class="show-echats"
                  :chartData="echatsData"
                  ref="chartRef"
                  v-if="echatsData"
                ></BarChart>
                <a-empty v-else />
              </div>
              <div class="bar-charts">
                <BarChart
                  v-if="echatsData2"
                  class="show-echats"
                  ref="chartRef2"
                  :chartData="echatsData2"
                ></BarChart>
                <a-empty v-else />
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from '../../../layout/Card.vue';
import CardTitle from '../../../layout/CardTitle.vue';
//'@views/dataCenter/components/layout/CardTitle.vue';
import TopCard from '../TopCard.vue';
// '@views/dataCenter/components/section/dataOverview/TopCard.vue';
import BarChart from './BarChart.vue';
import {
  getContentAnalysis,
  getContentAnalysisExcel
} from '../../../../api/index';
import ButtonDownload from '../../../btn/ButtonDownload.vue';
import ButtonExportData from '../../../btn/ButtonExportData.vue';

export default {
  name: 'datacenter-content-analytics',
  data() {
    return {
      dataSource: undefined,
      echatsData2: {},
      echatsData: {},
      loading: false
    };
  },
  components: {
    Card,
    CardTitle,
    TopCard,
    BarChart,
    ButtonDownload,
    ButtonExportData
  },
  methods: {
    async getData() {
      this.$refs.chartRef.showLoading();
      this.$refs.chartRef2.showLoading();
      this.loading = true;
      const data = await getContentAnalysis().finally(() => {
        this.$refs?.chartRef?.hideLoading();
        this.$refs?.chartRef2?.hideLoading();
        this.loading = false;
      });
      if (!(data && data.error_code === 0)) {
        return;
      }

      this.echatsData = {
        titles: data.data.sale_group.xAxis,
        title: '销售岗位',
        datas: data.data.sale_group.series.data,
        color: ['#324FA1', '#6187CF']
      };
      this.echatsData2 = {
        titles: data.data.service_group.xAxis,
        title: '服务岗位',
        datas: data.data.service_group.series.data,
        color: ['', '']
      };
      this.getCurseNumber(data.data);
    },
    async getCurseNumber(data) {
      this.dataSource = [
        {
          id: 1,
          title: '课程累计总数',
          content: data.total_course || 0,
          icon: 'user_checked',
          classTitle: '',
          class: 'bg1',
          small: true
        },
        {
          id: 2,
          title: '课程学习人数',
          content: data.total_study_time || 0,
          icon: 'user_effective',
          classTitle: '',
          class: 'bg2',
          small: true
        }
      ];
    },
    exportExcel() {
      this.loading = true;
      getContentAnalysisExcel({
        export_type: 'sales_group',
        is_download: 1
      });
      getContentAnalysisExcel({
        export_type: 'services_group',
        is_download: 1
      });
      this.loading = false;
    },
    downloadImageNew() {
      this.$refs.chartRef.saveImage();
      this.$refs.chartRef2.saveImage();
    }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style lang="less" scoped>
.content-analytics {
  .card {
    display: block;
    height: 519px;
  }
  .card-view {
    margin-top: 15px;
    display: flex;
    .left {
      display: inline-block;
      padding: 0;
      margin-right: 20px;
      p {
        padding: 0;
        margin: 0;
        text-align: center;
      }
      .title {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 17px;
        margin-top: 21px;
      }
      .content {
        font-size: 28px;
        font-family: DINCondensed-Bold, DINCondensed;
        font-weight: bold;
      }
      .bg1 {
        width: 186px;
        height: 92px;
        background: rgba(51, 79, 160, 0.14);
        border-radius: 4px;
        border: 1px solid rgba(41, 74, 165, 0.55);
        margin-top: 90px;
        .title {
          color: #334fa0;
        }
        .content {
          color: #334fa0;
        }
      }
      .bg2 {
        width: 186px;
        height: 92px;
        background: rgba(60, 133, 184, 0.14);
        border-radius: 4px;
        border: 1px solid rgba(60, 133, 184, 0.61);
        margin-top: 20px;
        .title {
          color: #3c85b8;
        }
        .content {
          color: #3c85b8;
        }
      }
    }
    .right {
      border-top: 1px solid #dddddd;
      flex: 1;
      .bar-list-charts {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .bar-charts {
          width: 340px;
          height: 320px;
          padding: 0 0 20px 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .addon {
    margin-left: 120px;
    ::v-deep .line {
      background: linear-gradient(270deg, #ba8cf0 0%, #8693f3 100%);
    }
  }
}
.show-echats {
  width:345px;
  height: 320px;
  padding: 0 0 10px 0;
}
</style>