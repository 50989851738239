<template>
  <div class="select-org" @click="showModal">
    <div class="content-wrapper">
      <div v-if="!list || !list.length" class="placeholder">请选择组织机构</div>
      <template v-else>
        <div v-if="list && list[0]" class="selected-item">
          <span>{{ list[0].name }}</span>
          <a-icon type="close" class="remove-icon" @click.stop="removeItem(list[0])"/>
        </div>
        <div v-if="list && list.length > 1" class="more-item-tag">
          <span>+{{ (list.length || 1) - 1 }}</span>
        </div>
      </template>
    </div>
    <div class="btn-wrapper">
      <a-icon type="down" class="arrow-down-icon"/>
      <a-icon type="close-circle"
              theme="filled"
              class="clear-icon"
              :class="{'none': !list || !list.length}"
              @click.stop="clear"/>
    </div>

    <SelectOrgModal ref="selectOrgModalRef"
                    :value="ids"
                    :same-level="sameLevel"
                    :not-all="notAll"
                    @changed="onChanged"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Common from '@/utils/Common';

import SelectOrgModal from './SelectOrgModal.vue';

export default {
  name: 'SelectOrg',
  components: {
    SelectOrgModal,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String, default: '' },
    sameLevel: { type: Boolean, default: false },
    notAll:{ type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      options: state => state.Common.orgTree,
    }),
    ids() {
      return (this.list?.map((i) => i.id) || []).join(',');
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.setDefault();
      },
    },
    ids: {
      immediate: true,
      handler() {
        this.$emit('change', this.ids);
        this.$emit('changed', this.list);
      },
    },
  },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    setDefault() {
      if (!this.value) {
        this.list = [];
        return;
      }

      const ids = this.value.split(',').map((i) => +i).filter((i) => !!i);
      this.list = Common.getSelectedItems(ids, this.options, { id: 'id', name: 'name' });
    },

    showModal() {
      if (this.$refs.selectOrgModalRef?.show) {
        this.$refs.selectOrgModalRef.show();
      }
    },
    onChanged(evt) {
      this.list = evt?.data || [];
    },
    removeItem(item) {
      const index = this.list.indexOf(item);
      if (index > -1) {
        this.list.splice(index, 1);
      }
    },
    clear() {
      this.list = [];
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/select.scss";

.select-org {
  @extend .wrapper;

  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 145px;

  &:hover .clear-icon:not(.none) {
    display: inline-block;
  }
}

.content-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 4px;
  padding-right: 4px;
}

.placeholder {
  padding-left: 9px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: #6677A5;
}

.selected-item {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 21px;
  line-height: 21px;
  margin-right: 4px;
  padding-left: 4px;
  padding-right: 20px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC, sans-serif;
  font-weight: 500;
  color: #334FA0;
  background: #EAEDF5;

  .remove-icon {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    font-size: 12px;
    color: #808287;
    cursor: pointer;
    zoom: 0.85;

    &:hover {
      color: #334FA0;
    }
  }
}

.more-item-tag {
  flex: none;
  height: 21px;
  line-height: 21px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC, sans-serif;
  font-weight: 500;
  color: #FFFFFF;
  background: #334FA0;
}

.btn-wrapper {
  position: relative;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: $height;
  border-left: $border;
}

.arrow-down-icon {
  font-size: 11px;
  color: #8FA7C3;
  transform: scaleY(0.85);
}

.clear-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  display: none;
  font-size: 12px;
  color: #BFBFBF;
  background-color: #FFFFFF;

  &:hover {
    color: #8C8C8C;
    cursor: pointer;
  }
}
</style>
