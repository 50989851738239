<template>
  <div class="data-overview">
    <TopCardList></TopCardList>
    <!-- 学员人数 -->
    <StudentIncreaseNumber></StudentIncreaseNumber>
    <ContentAnalytics></ContentAnalytics>
    <TalkDownload></TalkDownload>
    <TalkCenter></TalkCenter>
    <ActiveStatistics></ActiveStatistics>
  </div>
</template>

<script>
import TopCardList from './components/section/dataOverview/TopCardList.vue';
import TalkDownload from './components/section/dataOverview/functionalModules/TalkDownload.vue';
import ContentAnalytics from './components/section/dataOverview/functionalModules/ContentAnalytics.vue';
import StudentIncreaseNumber from './components/section/dataOverview/functionalModules/StudentIncreaseNumber.vue';
import TalkCenter from './components/section/dataOverview/functionalModules/TalkCenter.vue';
import ActiveStatistics from './components/section/dataOverview/functionalModules/ActiveStatistics.vue';

export default {
  name: 'DataOverview',
  components: {
    ContentAnalytics,
    StudentIncreaseNumber,
    TalkDownload,
    TopCardList,
    TalkCenter,
    ActiveStatistics
  }
};
</script>

<style scoped lang="scss">

.mt20 {
  margin-top: 20px;
}
.card-view {
  margin-top: 15px;
  border-top: 1px solid #dddddd;
  .saerch-list {
    margin-top: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    .search-card {
      margin-right: 10px;
      height: 29px;
      .search-month {
        display: inline-block;
        width: 29px;
        height: 29px;
        line-height: 29px;
        text-align: center;
        background: #ffffff;
        opacity: 0.41;
        border: 1px solid #bccee3;
      }
      .common-btn {
        height: 29px;
        background: #f9fafe;
        border-radius: 4px;
        border: 1px solid #e8e8e8;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #727b84;
      }
    }
  }
}
</style>
