<template>
  <a-radio-group v-model="activeId" class="tabs">
    <a-radio-button v-for="(item) in options"
                    :key="item.id"
                    :value="item.id"
                    class="tab"
                    :class="{active: activeId === item.id}">
      {{ item.name }}
    </a-radio-button>
  </a-radio-group>
</template>

<!--<Tabs v-model="tab" :options="[{id: -1, name: '全部'}, {id: 1, name: '销售岗位'}, {id: 2, name: '服务岗位'}]"/>-->

<script>
const all = {
  id: -1,
  name: '全部',
};

export default {
  name: 'Tabs',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: [Number, String], default: undefined },
    options: { type: Array, default: () => [{ ...all }] },
  },
  watch: {
    value: {
      immediate: false,
      handler(val) {
        this.activeId = val || this.activeId || undefined;
      },
    },
    activeId: {
      immediate: false,
      handler(val, old) {
        if (val !== old) {
          this.$emit('change', this.activeId);
          this.$emit('changed', this.options.filter(
            (i) => i.id === this.activeId,
          )?.[0] || null);
        }
      },
    },
  },
  data() {
    return {
      activeId: undefined,
    };
  },
  created() {
    if (this.options?.some((i) => i.id === this.value)) {
      this.activeId = this.value;
    } else {
      this.activeId = this.options?.[0]?.id || undefined;
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/base.scss";

.tabs {
  height: 31px;
  padding: 3px;
  border-radius: 4px;
  border: 1px solid #D5DCFC;
  background: #FFFFFF;

  .tab {
    @extend .pill-light;

    &::before {
      display: none;
    }

    &.active {
      @extend .pill-dark;
    }
  }
}
</style>
