<template>
  <ActiveStatisticsDate
    title="平台活跃统计-日活"
    :data="chartData"
    format="YYYY-MM-DD"
    type="days"
    :loading="loading"
    :allow-clear="false"
    :mode="['date', 'date']"
    :placeholder="['开始时间', '结束时间']"
    @search="onSearch"
    @reset="onReset"
    @export-excel="onExportExcel"
  />
</template>

<script>
import { getActiveDay, getActiveDayDownload } from '../../../../api';

import ActiveStatisticsDate from './ActiveStatisticsDate.vue';
import DateCommon from '@/utils/DateCommon';

export default {
  name: 'ActiveStatisticsDay',
  components: {
    ActiveStatisticsDate
  },
  data() {
    return {
      loading: false,
      date: undefined,
      chartData: null
    };
  },
  methods: {
    onSearch(evt) {
      this.date = evt?.date || undefined;
      this.getData();
    },
    onReset(evt) {
      this.date = evt?.date || undefined;
      this.getData();
    },
    async getData() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const params = this.getParams();
      const data = await getActiveDay(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '查询失败');
        return;
      }
      const result = data?.data || {};

      this.chartData = {
        date: data.meta || [],
        series: [result],
        title: result.name || '平台活跃统计-日活',
        yAxisName: '用户数（人）',
        yAxisLabelFormatter: '{value}',
        zoomX: true,
        hoverTitle:'日活'
      };
    },

    async onExportExcel() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const params = this.getParams();
      const data = await getActiveDayDownload({
        ...params,
        is_download: 1
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '导出数据失败');
      }
    },
    getParams() {
      return {
        start_day: DateCommon.getMomentStart('day', this.date?.[0]) || '',
        end_day: DateCommon.getMomentStart('day', this.date?.[1]) || ''
      };
    }
  }
};
</script>

<style scoped lang="scss">
</style>
