<template>
  <div class="card">
    <div v-if="showTitleBar" class="title-bar">
      <div class="title-wrapper">
        <CardTitle class="card-title">
          <slot name="title"></slot>
        </CardTitle>

        <slot name="addon"></slot>
      </div>

      <div>
        <slot name="extra"></slot>
      </div>
    </div>

    <slot/>
  </div>
</template>

<script>
import CardTitle from './CardTitle.vue';

export default {
  name: 'Card',
  components: {
    CardTitle,
  },
  props: {
    showTitleBar: { type: Boolean, default: true },
  },
};
</script>

<style scoped lang="scss">
.card {
  margin-bottom: 16px;
  padding: 16px 20px;
  border-radius: 4px 4px 4px 4px;
  background: #FFFFFF;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);

  .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 38px;
    margin-bottom: 20px;
  }

  .title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .card-title {
    margin-right: 20px;
  }
}
</style>
