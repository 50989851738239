<template>
  <div class="active-statistics-overview">
    <div class="query-1 main-selected-color-wrapper">
      <SelectDate v-model="date" :show-suffix="false" class="select-date" />
      <ButtonSmall
        type="primary"
        :loading="loading"
        class="btn"
        @click="onSearch"
        >查询</ButtonSmall
      >
    </div>
    <div class="query-2">
      <ButtonSmall :loading="loading" @click="onReset">重置</ButtonSmall>
    </div>

    <div class="result-wrapper">
      <div class="small-card day-active">
        <div class="name">日活跃人数</div>
        <div class="value">{{ dayActiveNum }}</div>
      </div>
      <div class="small-card month-active">
        <div class="name">月活跃人数</div>
        <div class="value">{{ monthActiveNum }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import { getActiveOverview } from '../../../../api';

import SelectDate from '../../../selection/SelectDate.vue';
import ButtonSmall from '../../../btn/ButtonSmall.vue';

export default {
  name: 'ActiveStatisticsOverview',
  components: {
    SelectDate,
    ButtonSmall
  },
  data() {
    return {
      loading: false,
      date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      dayActiveNum: 0,
      monthActiveNum: 0
    };
  },
  created() {
    this.onReset();
  },
  methods: {
    onSearch() {
      this.getData();
    },
    onReset() {
      this.date = [
        moment().subtract(1, 'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ];
      this.getData();
    },
    async getData() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const params = this.getParams();
      const data = await getActiveOverview(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取平台活跃人数失败');
        return;
      }
      this.dayActiveNum = data?.data?.active_day || 0;
      this.monthActiveNum = data?.data?.active_month || 0;
    },
    getParams() {
      return {
        start_day: this.date[0]
      };
    }
  }
};
</script>

<style scoped lang="scss">
.active-statistics-overview {
  width: 200px;
  margin-right: 10px;
}

.query-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding-top: 6px;
}

.query-2 {
  margin-bottom: 10px;
}

.select-date {
  width: 150px;
  border-right-width: 0;
}

.btn {
  border-radius: 0;
}

.result-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 480px;
}

.small-card {
  flex: none;
  width: 186px;
  height: 92px;
  padding-top: 20px;
  padding-bottom: 15px;
  border: 1px solid #dbdef1;
  margin-bottom: 20px;
  background-color: #f1f1fb;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  .name {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC, sans-serif;
    font-weight: 400;
    color: #334fa0;
  }

  .value {
    font-size: 28px;
    font-family: DINCondensed-Bold, DINCondensed, sans-serif;
    font-weight: bold;
    color: #334fa0;
  }

  &.month-active {
    .name {
      color: #3c85b8;
    }

    .value {
      color: #3c85b8;
    }
  }
}
</style>
