<template>
  <SelectRangeQuarter
    v-model="quarter"
    :single="true"
    :show-suffix="true"
  />
</template>

<script>
import moment from 'moment';

import SelectRangeQuarter from './SelectRangeQuarter.vue';

export default {
  name: 'SelectQuarter',
  components: {
    SelectRangeQuarter
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: Array, default: () => [undefined, undefined] },
    showSuffix: { type: Boolean, default: true }
  },
  watch: {
    quarter: {
      immediate: false,
      deep: true,
      handler(val, old) {
        if (
          val &&
          val?.length === 2 &&
          old?.length === 2 &&
          val.every((i, idx) => i === old?.[idx])
        ) {
          return;
        }

        this.$emit('change', [
          this.quarter?.[0]
            ? moment(this.quarter[0], 'YYYY-Q')
                .startOf('quarters')
                .add(1, 'minutes')
            : undefined,
          this.quarter?.[0]
            ? moment(this.quarter[0], 'YYYY-Q')
                .endOf('quarters')
                .subtract(1, 'minutes')
            : undefined
        ]);
        this.$emit('changed', {
          type: 'quarter',
          date: this.quarter[0] ? this.quarter[0] : ''
        });
      }
    },
    value: {
      immediate: true,
      handler(val, old) {
        if (
          val &&
          val?.length === 2 &&
          old?.length === 2 &&
          val.every((i, idx) => i === old?.[idx])
        ) {
          return;
        }
        this.quarter = [
          val?.[0] ? val[0].clone().add(1, 'days').format('YYYY-Q') : undefined,
          undefined
        ];
      }
    }
  },
  data() {
    return {
      quarter: [undefined, undefined]
    };
  },
};
</script>

<style scoped lang="scss">
</style>
