<template>
  <a-tree-select
    v-model="selected"
    :tree-data="options"
    :get-popup-container="(current) => current.parentNode"
    :allow-clear="true"
    :show-arrow="true"
    :tree-checkable="true"
    :max-tag-count="1"
    placeholder="请选择课程"
    tree-node-filter-prop="title"
    class="select"
  >
    <template slot="maxTagPlaceholder">
      <span>{{ selected.length || 1 }}</span>
    </template>
    <template slot="suffixIcon">
      <a-icon type="down" />
    </template>
  </a-tree-select>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SelectCourse',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: String, default: '' }
  },
  computed: {
    ...mapState({
      options: (state) => state.DataCenter.tagCourses || []
    })
  },
  watch: {
    options: {
      immediate: true,
      handler() {
        this.setDefault();
      }
    },
    value: {
      immediate: true,
      handler(val, old) {
        // 判断 val 与 old 是否包含了完全相同的 ids
        const valArr = (val || '').split(',').filter((i) => !!i);
        const oldArr = (old || '').split(',').filter((i) => !!i);

        let idx = -1;
        for (let i = valArr.length; i >= 0; i -= 1) {
          idx = oldArr.indexOf(valArr[i]);
          if (idx > -1) {
            valArr.slice(i, 1);
            oldArr.splice(idx, 1);
          }
        }

        if (valArr.length === 0 && oldArr.length === 0) {
          return;
        }

        if (val !== old) {
          this.setDefault();
        }
      }
    },
    selected: {
      immediate: true,
      handler(val, old) {
        const valArr = [...(val || [])];
        const oldArr = [...(old || [])];

        let idx = -1;
        for (let i = valArr.length; i >= 0; i -= 1) {
          idx = oldArr.indexOf(valArr[i]);
          if (idx > -1) {
            valArr.slice(i, 1);
            oldArr.splice(idx, 1);
          }
        }

        if (valArr.length === 0 && oldArr.length === 0) {
          return;
        }

        this.$emit('change', this.getIdsBySelected());
      }
    }
  },
  data() {
    return {
      selected: []
    };
  },
  created() {
    if (!this.options?.length) {
      this.$store.dispatch('DataCenter/getTagCourses');
    }
  },
  methods: {
    setDefault() {
      this.getSelectedByIds();
    },
    getSelectedByIds() {
      const ids = (this.value || '')
        .split(',')
        .map((i) => +i)
        .filter((i) => !!i);
      const options = [...(this.options || [])];
      const selected = [];

      for (let i = 0; i < options.length; i += 1) {
        if (ids.includes(options[i].id)) {
          selected.push(options[i].value);
        }

        if (options[i].children?.length) {
          options.push(...options[i].children);
        }
      }

      this.selected = selected;
    },
    getIdsBySelected() {
      const selected = [...this.selected];
      const options = [...(this.options || [])];
      const ids = [];

      for (let i = 0; i < options.length; i += 1) {
        if (selected.includes(options[i].value)) {
          ids.push(options[i].id);
        }

        if (options[i].children?.length) {
          options.push(...options[i].children);
        }
      }

      return ids.join(',');
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../scss/select.scss';

.select {
  width: 141px;
  height: 32px;
  ::v-deep {
    .ant-select-selection__rendered {
      display: flex;
      width: 110px;
      padding-left: 4px;
      padding-right: 4px;
    }

    .ant-select-search__field__placeholder {
      left: 9px;
      font-size: 12px;
      color: #6677a5;
    }

    .ant-select-selection__choice {
      padding-left: 6px;

      &:not([title]) {
        flex: none;
        padding-left: 4px;
        padding-right: 4px;
        border-radius: 3px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC, sans-serif;
        font-weight: 500;
        color: #ffffff;
        background: #334fa0;

        .ant-select-selection__choice__content {
          height: 100%;
        }
      }
    }

    .ant-select-selection__clear {
      right: 8px;
    }
  }
}
</style>
