<template>
  <div class="range-week" @click.stop="showPanel">
    <a-week-picker
      :value="startDate"
      ref="startWeekPicker"
      :open="open"
      :get-calendar-container="getContainerStart"
      :allow-clear="false"
      :disabled-date="disabledStart"
      format="YYYY-wo"
      placeholder="开始周"
      class="select-week start"
      @change="onChangeStart"
    />
    <span class="separator" :class="{ 'replace-margin': open }">至</span>
    <a-week-picker
      :value="endDate"
      :open="open"
      :get-calendar-container="getContainerEnd"
      :allow-clear="false"
      :disabled-date="disabledEnd"
      format="YYYY-wo"
      placeholder="结束周"
      class="select-week end"
      :class="{ opened: open }"
      @change="onChangeEnd"
    />
    <div ref="weekEndContainerRef" class="week-end-container select-week" />
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'SelectRangeWeek',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: Array, default: () => [undefined, undefined] }
  },
  computed: {
    getContainerStart() {
      return () => this.$refs.startWeekPicker.$el;
    },
    getContainerEnd() {
      return () => this.$refs.weekEndContainerRef;
    }
  },
  watch: {
    startDate() {
      this.emit();
    },
    endDate() {
      this.emit();
    },
    value: {
      immediate: true,
      handler(val, old) {
        if (
          val &&
          val?.length === 2 &&
          old?.length === 2 &&
          val.every((i, idx) => i === old?.[idx])
        ) {
          return;
        }
        this.dateRange = [
          val?.[0] ? moment(val[0], 'YYYY-MM-DD') : undefined,
          val?.[1] ? moment(val[1], 'YYYY-MM-DD') : undefined
        ];
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.hidePanel, false);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hidePanel);
  },
  data() {
    return {
      startDate: undefined,
      endDate: undefined,

      open: false
    };
  },
  methods: {
    showPanel() {
      this.open = true;
    },
    hidePanel() {
      this.open = false;
    },

    disabledStart(current) {
      return (
        this.endDate && !this.endDate.clone().startOf('weeks').isAfter(current)
      );
    },
    disabledEnd(current) {
      return (
        this.startDate &&
        !this.startDate.clone().endOf('weeks').isBefore(current)
      );
    },

    onChangeStart(date) {
      console.log(date);
      this.startDate = moment(date,'YYYY-wo').startOf('weeks');
    },
    onChangeEnd(date) {
      this.endDate = date;
      if (this.startDate) {
        setTimeout(() => (this.open = false), 0);
      }
    },

    emit() {
      const dates = [
        this.startDate?.format('YYYY-ww') || undefined,
        this.endDate?.format('YYYY-ww') || undefined
      ];
      this.$emit('change', dates);
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../scss/select-date.scss';

.range-week {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 31px;
  border: $border;
  padding: 0 9px;
}

.separator {
  margin-left: 4px;
  margin-right: 4px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: #6677a5;

  &.replace-margin {
    margin-right: 84px;
  }
}

.select-week {
  flex: auto;
  width: 78px;
  height: 29px;
  border: none;

  &.opened {
    position: absolute;
    left: 288px;
  }

  ::v-deep {
    .ant-calendar-picker-input {
      text-align: center;
    }

    .ant-calendar-picker-icon {
      display: none;
      width: 0;
    }
  }
}

.week-end-container {
  position: absolute;
  left: 288px;
  width: 0;
  height: 0;
}
</style>
