<template>
  <a-month-picker :value="date"
                  :get-calendar-container="(current) => current.parentNode.parentNode"
                  format="YYYY-MM"
                  placeholder="请选择月份"
                  class="select-month"
                  @change="onChange">
    <template slot="suffixIcon">
      <a-icon type="down"/>
    </template>
  </a-month-picker>
</template>

<script>
import SelectDateMixin from '../../mixin/SelectDateMixin';

export default {
  name: 'SelectMonth',
  mixins: [SelectDateMixin],
  data() {
    return {
      type: 'month',
      format: 'YYYY-MM',
    };
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/select-date.scss";
</style>
