<template>
  <Card class="student-increase-number">
    <template slot="title">学员新增人数</template>
    <template slot="extra">
      <ButtonDownload @click="downloadImage('学员新增人数')" />
      <ButtonExportData @click="exportExcel" :loading="loading" />
    </template>

    <div class="query-1 main-selected-color-wrapper">
      <SelectDateType
        v-model="dateType"
        class="date-type"
        @change="dateTypeChange"
      />

      <SelectRangeQuarter
        v-if="dateType === 'quarter'"
        v-model="date"
        class="no-border-right"
      />
      <SelectRangeWeek
        v-else-if="dateType === 'week'"
        v-model="date"
        class="no-border-right"
      />
      <SelectRangePicker
        v-else
        v-model="date"
        :type="dateType"
        :format="format"
        :mode="mode"
        :placeholder="placeholder"
        :allow-clear="false"
        :show-suffix="false"
        class="no-border-right"
      />
      <ButtonSmall
        type="primary"
        class="btn"
        @click="onSearch"
        :loading="loading"
        >查询</ButtonSmall
      >
      <ButtonSmall @click="onReset" :loading="loading">重置</ButtonSmall>
      <ButtonSmall @click="showHiddenModal">可隐藏项</ButtonSmall>
    </div>

    <SingleLineChart
      ref="chartRef"
      v-if="originData"
      :chart-data="chartData"
      class="chart"
    />
    <a-empty v-else style="margin: 60px 0"></a-empty>

    <CheckboxModal
      ref="checkboxModalRef"
      :value="visibleIds"
      title="可隐藏项"
      :options="allVisibleItems"
      @save="visibleSave"
    />
  </Card>
</template>

<script>
import moment from 'moment';
import {
  getStudentIncreaseNumber,
  getStudentIncreaseNumberDownload
} from '../../../../api';

import ToggleChartVisible from '../../../../mixin/ToggleChartVisible';
import Card from '../../../layout/Card.vue';
import ButtonDownload from '../../../btn/ButtonDownload.vue';
import ButtonExportData from '../../../btn/ButtonExportData.vue';
import ButtonSmall from '../../../btn/ButtonSmall.vue';
import SelectDateType from '../../../selection/SelectDateType.vue';
import SelectRangePicker from '../../../selection/SelectRangePicker.vue';
import SelectRangeQuarter from '../../../selection/SelectRangeQuarter.vue';
import SelectRangeWeek from '../../../selection/SelectRangeWeek.vue';
import SingleLineChart from '../../../charts/SingleLineChart.vue';
import DateCommon from '@/utils/DateCommon';

export default {
  name: 'StudentIncreaseNumber',
  components: {
    Card,
    ButtonDownload,
    ButtonExportData,
    ButtonSmall,
    SelectDateType,
    SelectRangePicker,
    SelectRangeQuarter,
    SelectRangeWeek,
    SingleLineChart
  },
  mixins: [ToggleChartVisible],
  data() {
    return {
      loading: false,
      date: undefined,
      originData: {},
      dateType: 'month',
      format: 'YYYY-MM',
      mode: ['month', 'month'],
      placeholder: ['开始月', '结束月']
    };
  },
  mounted() {
    this.onReset();
  },
  methods: {
    onSearch() {
      this.getData();
    },
    onReset() {
      this.dateType = 'month';
      this.dateTypeChange();

      this.date = [
        moment().subtract(6, 'months').format('YYYY-MM'),
        moment().subtract(1, 'months').format('YYYY-MM')
      ];
      this.getData();
    },
    async getData() {
      if (this.loading) {
        return;
      }

      const params = this.getParams();
      if (!params) {
        return;
      }
      this.loading = true;
      this.$refs?.chartRef?.showLoading();
      const data = await getStudentIncreaseNumber(params).finally(() => {
        this.$refs?.chartRef?.hideLoading();
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '查询失败');
        this.originData = undefined;
        return;
      }
      const result = data?.data || {};
      this.originData = {
        date: data.meta?.horizontal_axis || [],
        series: [result],
        color: data.meta?.color?.length ? data.meta.color : undefined,
        title: '学员新增人数',
        yAxisName: '用户数（人）',
        yAxisLabelFormatter: '{value}'
      };
      this.visibleIds = this.allVisibleItems
        .map((i) => i.id)
        .filter((i) => !!i)
        .join(',');
    },
    async exportExcel() {
      //  this.$emit('export-excel', { date: this.date, format: this.format });
      const params = this.getParams();
      if (!params) {
        return;
      }
      this.loading = true;
      const data = await getStudentIncreaseNumberDownload({
        ...params,
        is_download: 1
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '导出数据失败');
      }
      this.$message.success('导出成功');
    },

    dateTypeChange() {
      const type = this.dateType || 'month';
      const maps = {
        year: {
          format: 'YYYY',
          mode: ['year', 'year'],
          placeholder: ['开始年', '结束年']
        },
        quarter: {
          format: 'YYYY-Q',
          mode: ['quarter', 'quarter'],
          placeholder: ['开始季', '结束季']
        },
        month: {
          format: 'YYYY-MM',
          mode: ['month', 'month'],
          placeholder: ['开始月', '结束月']
        },
        week: {
          format: 'YYYY-ww',
          mode: ['week', 'week'],
          placeholder: ['开始周', '结束周']
        }
      };
      this.format = maps[type].format;
      this.mode = maps[type].mode;
      this.placeholder = maps[type].placeholder;

      this.date = undefined;
    },
    showLoading() {
      this.chart.showLoading();
    },
    hideLoading() {
      this.chart.hideLoading();
    },
    getParams() {
      if (!(this.date && this.date?.[0])) {
        this.$message.error('请选择时间');
        return false;
      }
      return {
        day_type: this.dateType || 'year',
        start_day: DateCommon.getMomentStart(this.dateType, this.date[0]) || '',
        end_day: DateCommon.getMomentEnd(this.dateType, this.date[1]) || '',
        is_download: 0
      };
    }
  }
};
</script>

<style lang="less" scoped>
.student-increase-number {
  ::v-deep .title-bar {
    margin-bottom: 15px;
  }
}

.query-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid #dddddd;

  .date-type {
    margin-right: 10px;
  }

  .no-border-right {
    border-right-width: 0;
  }

  .btn {
    border-radius: 0;
  }
}
</style>
