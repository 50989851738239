<template>
  <Card class="speech-skill-center">
    <template slot="title">话术中心</template>
    <Tabs
      @change="onChange"
      slot="addon"
      v-model="postType"
      :options="postTypes"
    />
    <template slot="extra">
      <ButtonDownload @click="downloadImage(`话术中心-使用${typeName}`)" />
      <ButtonExportData @click="exportExcel" :loading="loading" />
    </template>

    <div class="query-1 main-selected-color-wrapper">
      <SelectRangePicker
        v-model="date"
        :format="'YYYY-MM'"
        :allow-clear="false"
        :mode="['month', 'month']"
        :show-suffix="false"
        :placeholder="['开始月', '结束月']"
        class="select-date"
      />
      <ButtonSmall
        type="primary"
        class="btn"
        @click="onSearch"
        :loading="loading"
        >查询</ButtonSmall
      >
      <ButtonSmall @click="onReset" :loading="loading">重置</ButtonSmall>
      <ButtonSmall @click="showHiddenModal">可隐藏项</ButtonSmall>
    </div>

    <SingleLineChart
      ref="chartRef"
      v-if="originData"
      :chart-data="chartData"
      class="chart"
    />

    <CheckboxModal
      ref="checkboxModalRef"
      :value="visibleIds"
      title="可隐藏项"
      :options="allVisibleItems"
      @save="visibleSave"
    />
  </Card>
</template>

<script>
import moment from 'moment';

import ToggleChartVisible from '../../../../mixin/ToggleChartVisible';

import Card from '../../../../components/layout/Card.vue';
import ButtonDownload from '../../../btn/ButtonDownload.vue';
import ButtonExportData from '../../../btn/ButtonExportData.vue';
import ButtonSmall from '../../../btn/ButtonSmall.vue';
import Tabs from '../../../layout/Tabs.vue';
import SelectRangePicker from '../../../selection/SelectRangePicker.vue';
import SingleLineChart from '../../../charts/SingleLineChart.vue';
import {
  getSpeechSkillUseTimes,
  excelSpeechSkillUseTimes
} from '../../../../api/index';
import DateCommon from '@/utils/DateCommon';

export default {
  name: 'TalkCenter',
  components: {
    Card,
    ButtonDownload,
    ButtonExportData,
    ButtonSmall,
    Tabs,
    SelectRangePicker,
    SingleLineChart
  },
  mixins: [ToggleChartVisible],
  data() {
    return {
      //  date: null,
      loading: false,
      postType: 'select_use_num',
      postTypes: [
        { id: 'select_use_num', name: '次数' },
        { id: 'select_use_time', name: '时长' }
      ],
      date: [
        moment().subtract(6, 'months').format('YYYY-MM'),
        moment().subtract(1, 'months').format('YYYY-MM')
      ],
      originData: {},
      typeName: '次数',
      typeUnit: '次'
    };
  },
  // mounted() {
  //   this.onReset();
  // },
  methods: {
    onSearch() {
      this.getData();
    },
    onReset() {
      this.date = [
        moment().subtract(6, 'months').format('YYYY-MM'),
        moment().format('YYYY-MM')
      ];
      this.getData();
    },
    async getData() {
      const parmas = this.getParams();
      if (!parmas) {
        return false;
      }
      await this.getSpeechSkillUseTimes(parmas);
      this.visibleIds = this.allVisibleItems
        .map((i) => i.id)
        .filter((i) => !!i)
        .join(',');
    },
    async getSpeechSkillUseTimes(params) {
      const parmas = this.getParams();
      if (!parmas) {
        return false;
      }
      this.loading = true;
      this.$refs.chartRef.showLoading();
      const data = await getSpeechSkillUseTimes(params).finally(() => {
        this.loading = false;
        this.$refs?.chartRef?.hideLoading();
      });
      if (!(data && data.error_code === 0)) {
        this.originData = undefined;
        return;
      }
      this.originData = {
        date: data.data.xAxis.data,
        series: [
          {
            name: `话术中心 - 使用${this.typeName}`,
            type: 'line',
            data: data.data.series.data
          }
        ],
        color: ['#324FA1', '#324FA1', '#F0C944', '#97D9C1', '#6F68AC'],
        title: `话术中心 - 使用${this.typeName}`,
        yAxisName: `使用${this.typeName} (${this.typeUnit})`,
        yAxisLabelFormatter: '{value}'
      };
    },
    async exportExcel() {
      const params = this.getParams();
      this.loading = true;
      const data = await excelSpeechSkillUseTimes({
        ...params,
        is_download: 1
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '导出数据失败');
      }
      this.$message.success('导出成功');
    },
    onChange(id) {
      if (id === 'select_use_time') {
        this.typeName = '时长';
        this.typeUnit = '分';
      } else if (id === 'select_use_num') {
        this.typeName = '次数';
        this.typeUnit = '次';
      }
      this.onSearch();
    },
    getParams() {
      if (!(this.date && this.date?.[0])) {
        this.$message.error('请选择时间');
        return false;
      }
      return {
        start_day: DateCommon.getMomentStart('month', this.date[0]),
        end_day: DateCommon.getMomentEnd('month', this.date[1]),
        select_type: this.postType
      };
    }
  }
};
</script>

<style scoped lang="less">
.speech-skill-center {
  ::v-deep .title-bar {
    margin-bottom: 15px;
  }
}

.query-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid #dddddd;

  .select-date {
    border-right-width: 0;
  }

  .btn {
    border-radius: 0;
  }
}
</style>
