<template>
  <div class="select-org-check" @click="showModal">
    <div class="content-wrapper">
      <div v-if="!showTitle" class="placeholder">请选择店类型</div>
      <template v-else>
        <div v-if="showTitle" class="selected-item">
          <span>{{ showTitle }}</span>
          <a-icon type="close"
                  class="remove-icon"
                  @click.stop="removeItem(item)"/>
        </div>
        <div v-if="count && count > 1" class="more-item-tag">
          <span>+{{ count - 1 }}</span>
        </div>
      </template>
    </div>
    <div class="btn-wrapper">
      <a-icon type="down" class="arrow-down-icon"/>
      <a-icon type="close-circle"
              theme="filled"
              class="clear-icon"
              :class="{ none: count > 0 ? false : true }"
              @click.stop="clear"/>
    </div>
    <SelectShopTypeModal ref="selectOrgModalRef"
                         v-model="pramas"
                         :option="options"
                         @success="onSuccess"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SelectShopTypeModal from '@/components/select/SelectShopTypeModal.vue';

export default {
  name: 'SelectShopType',
  components: {
    SelectShopTypeModal,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object, default: () => {
      },
    },
  },
  computed: {
    ...mapState({
      options: (state) => {
        return state.Common.orgStoreType;
      },
    }),
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        console.log('watch----value', value);
        this.setParamsValue(value);
        //  this.setDefault();
      },
    },
  },
  data() {
    return {
      pramas: {
        store_state: '',
        store_type: '',
        operation_state: '',
        store_age: '',
      },
      pramas2: {
        store_state: [],
        store_type: [],
        operation_state: [],
        store_age: [],
      },
      count: 0,
      showTitle: '',
      item: {
        name: '',
      },
    };
  },
  methods: {
    setParamsValue(value) {
      this.pramas2 = {
        store_state:
          value?.store_state
          ?.toString()
          ?.split(',')
          ?.filter((s) => s !== '') ?? [],
        store_type:
          value?.store_type
          ?.toString()
          ?.split(',')
          ?.filter((s) => s !== '') ?? [],
        operation_state:
          value?.operation_state
          ?.toString()
          ?.split(',')
          ?.filter((s) => s !== '') ?? [],
        store_age:
          value?.store_age
          ?.toString()
          ?.split(',')
          ?.filter((s) => s !== '') ?? [],
      };
    },
    showModal() {
      if (this.$refs.selectOrgModalRef?.show) {
        this.$refs.selectOrgModalRef.show(this.pramas);
      }
    },
    // onChanged(evt) {
    //   this.list = evt?.data || [];
    // },
    removeItem(item) {
      this.pramas2[item.name].splice(0, 1);
      this.onSuccess(this.pramas2);
    },
    clear() {
      // this.list = [];
      this.pramas2 = {
        store_state: [],
        store_type: [],
        operation_state: [],
        store_age: [],
      };
      this.onSuccess(this.pramas2);
    },
    onSuccess(value) {
      let item = {};
      if (value.store_state.length > 0) {
        item = this.options.store_state.find(
          (s) => s.value.toString() === value.store_state[0],
        );
        this.item = {
          name: 'store_state',
        };
      } else if (value.store_type.length > 0) {
        item = this.options.store_type.find(
          (s) => s.value.toString() === value.store_type[0],
        );
        this.item = {
          name: 'store_type',
        };
      } else if (value.operation_state.length > 0) {
        item = this.options.operation_state.find(
          (s) => s.value.toString() === value.operation_state[0],
        );
        this.item = {
          name: 'operation_state',
        };
      } else if (value.store_age.length > 0) {
        item = this.options.store_age.find(
          (s) => s.value.toString() === value.store_age[0],
        );
        this.item = {
          name: 'store_age',
        };
      }
      this.showTitle = item?.label;
      this.change(value);
    },
    change(value) {
      this.pramas2 = {
        store_state: value.store_state
        .toString()
        .split(',')
        ?.filter((s) => s !== ''),
        store_type: value.store_type
        .toString()
        .split(',')
        ?.filter((s) => s !== ''),
        operation_state: value.operation_state
        .toString()
        ?.split(',')
        ?.filter((s) => s !== ''),
        store_age: value.store_age
        .toString()
        ?.split(',')
        ?.filter((s) => s !== ''),
      };
      this.count =
        this.pramas2.store_age.length +
        this.pramas2.operation_state.length +
        this.pramas2.store_type.length +
        this.pramas2.store_state.length;
      const resultParams = {
        store_state: value.store_state.toString(),
        store_type: value.store_type.toString(),
        operation_state: value.operation_state.toString(),
        store_age: value.store_age.toString(),
      };
      this.pramas = {
        store_state: value.store_state.toString(),
        store_type: value.store_type.toString(),
        operation_state: value.operation_state.toString(),
        store_age: value.store_age.toString(),
      };
      this.$emit('change', resultParams);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../scss/select.scss';

.select-org-check {
  @extend .wrapper;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 145px;

  &:hover .clear-icon:not(.none) {
    display: inline-block;
  }
}

.content-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 4px;
  padding-right: 4px;
}

.placeholder {
  padding-left: 9px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: #6677a5;
}

.selected-item {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 21px;
  line-height: 21px;
  margin-right: 4px;
  padding-left: 4px;
  padding-right: 20px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC, sans-serif;
  font-weight: 500;
  color: #334fa0;
  background: #eaedf5;

  .remove-icon {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    font-size: 12px;
    color: #808287;
    cursor: pointer;
    zoom: 0.85;

    &:hover {
      color: #334fa0;
    }
  }
}

.more-item-tag {
  flex: none;
  height: 21px;
  line-height: 21px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC, sans-serif;
  font-weight: 500;
  color: #ffffff;
  background: #334fa0;
}

.btn-wrapper {
  position: relative;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: $height;
  border-left: $border;
}

.arrow-down-icon {
  font-size: 11px;
  color: #8fa7c3;
  transform: scaleY(0.85);
}

.clear-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  display: none;
  font-size: 12px;
  color: #bfbfbf;
  background-color: #ffffff;

  &:hover {
    color: #8c8c8c;
    cursor: pointer;
  }
}
</style>
