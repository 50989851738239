<template>
  <div ref="chartRef" class="chart"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'TwoSegmentsCircleChart',
  props: {
    chartData: { type: Object, default: () => ({}) },
  },
  watch: {
    chartData(data) {
      this.updateData(data);
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      const ref = this.$refs.chartRef;
      if (!ref) {
        return;
      }

      this.chart = echarts.init(ref);
      this.updateData(this.chartData);
    },
    updateData(data) {
      if (!this.chart) {
        this.initChart();
        return;
      }
      if (this.chart.showLoading) {
        this.chart.showLoading();
      }

      const series = data?.series || [];

      function renderItem(params, api) {
        // console.log(params, api);
        const pixelRatio = api.getDevicePixelRatio() || 1;
        const canvasWidth = api.getWidth();
        const canvasHeight = api.getHeight() - 40;
        const width = Math.min(canvasWidth, 214);
        const height = Math.min(canvasHeight, 360);
        const size = Math.min(width, height) / 2 - 30 * pixelRatio;
        const value0 = api.value(0);
        const value1 = api.value(1);
        const value = value0 + value1;
        let SetRate = 0;
        let rate = '';
        SetRate = (100 * value0) / value;
        if (SetRate % 1 > 0) {
          rate = `${((100 * value0) / value).toFixed(1)}%`;
        } else if (((SetRate % 1) * 10) % 1 > 0) {
          rate = `${((100 * value0) / value).toFixed(2)}%`;
        } else {
          rate = `${((100 * value0) / value).toFixed(0)}%`;
        }

        if (!value0 && !value1) {
          return {
            type: 'group',
            children: [],
          };
        }

        return {
          type: 'group',
          children: [
            {
              type: 'arc',
              shape: {
                cx: canvasWidth / 2,
                cy: height / 2,
                r: size,
                startAngle: 0,
                endAngle: (2 * Math.PI * value0) / value,
              },
              style: {
                lineWidth: 40 * pixelRatio,
                stroke: {
                  type: 'linear',
                  x: 1,
                  y: 0,
                  x2: 0,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#3D84B8',
                    },
                    {
                      offset: 1,
                      color: '#324FA1',
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            {
              type: 'arc',
              shape: {
                cx: canvasWidth / 2,
                cy: height / 2,
                r: size - 5,
                startAngle: (2 * Math.PI * value0) / value,
                endAngle: 2 * Math.PI,
              },
              style: {
                lineWidth: 30 * pixelRatio,
                stroke: '#FFEBA1',
              },
            },
            {
              type: 'arc',
              shape: {
                cx: canvasWidth / 2,
                cy: height / 2,
                r: size - 5,
                startAngle: 0,
                endAngle: 2 * Math.PI,
              },
              style: {
                fill: '#FFFFFF',
              },
            },
            {
              type: 'text',
              style: {
                text: '参训率',
                x: width / 2,
                y: (height / 2) * 0.85,
                font: '400 12px PingFangSC-Regular, "PingFang SC"',
                textAlign: 'center',
                verticalAlign: 'center',
                fill: '#294AA5',
              },
            },
            {
              type: 'text',
              style: {
                text: rate,
                x: width / 2 + 5,
                y: (height / 2) * 1.15,
                font: 'bold 32px DINCondensed-Bold, DINCondensed, sans-serif',
                textAlign: 'center',
                verticalAlign: 'center',
                fill: '#294AA5',
              },
            },
            {
              type: 'group',
              x: canvasWidth / 2 - 80,
              y: height - 30,
              children: [
                {
                  type: 'rect',
                  shape: {
                    width: 10,
                    height: 10,
                    startAngle: 0,
                    endAngle: (2 * Math.PI * value0) / value,
                  },
                  style: {
                    fill: '#324FA1',
                  },
                },
                {
                  type: 'text',
                  style: {
                    text: '已参训',
                    x: 40,
                    y: 0,
                    font: 'bold 12px PingFangSC-Semibold, PingFang SC, sans-serif',
                    textAlign: 'center',
                    verticalAlign: 'top',
                    fill: '#334FA0',
                  },
                },
              ],
            },
            {
              type: 'group',
              x: canvasWidth / 2 + 20,
              y: height - 30,
              children: [
                {
                  type: 'rect',
                  shape: {
                    width: 10,
                    height: 10,
                    startAngle: 0,
                    endAngle: (2 * Math.PI * value0) / value,
                  },
                  style: {
                    fill: '#FFDF68',
                  },
                },
                {
                  type: 'text',
                  style: {
                    text: '未参训',
                    x: 40,
                    y: 0,
                    font: 'bold 12px PingFangSC-Semibold, PingFang SC, sans-serif',
                    textAlign: 'center',
                    verticalAlign: 'top',
                    fill: '#FFDF68',
                  },
                },
              ],
            },
          ],
        };
      }

      const option = {
        dataset: {
          source: [series.map((i) => i.data)],
        },
        legend: {
          show: true,
          type: 'plain',
          bottom: 10,
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          // eslint-disable-next-line func-names
          formatter: (params) => {
            if (!params?.length) {
              return;
            }
            // eslint-disable-next-line no-shadow
            const data = params[0]?.data;
            const items = data
              .map((i, idx) => {
                console.log('map', i, idx);
                return `<span style="display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 6px;
                margin-right: 10px;
                background-color:
                ${idx ? '#FFEBA1' : '#324FA1'}
                ;"></span>
                <span style="display: inline-block; width: 100px;">
                ${idx ? '未参训' : '已参训'}
                </span>
                <span style="display: inline-block; font-weight: 600;">
                ${i?.toFixed(1) ?? 0}%</span>
                <br/>`;
              })
              .join('');
            // eslint-disable-next-line consistent-return
            return `参训率<br/>${items}`;
          },
          axisPointer: {
            type: 'none',
          },
        },
        angleAxis: {
          type: 'value',
          startAngle: 0,
          show: false,
          min: 0,
          max: 200,
        },
        radiusAxis: {
          type: 'value',
          show: false,
        },
        polar: {},
        series: [
          {
            type: 'custom',
            coordinateSystem: 'polar',
            renderItem,
          },
        ],
      };

      this.chart.setOption(option, true);
      this.chart.hideLoading();
    },
    showLoading() {
      this.chart.showLoading();
    },
    hideLoading() {
      this.chart.hideLoading();
    },
  },
};
</script>

<style scoped lang="scss">
.chart {
  flex: none;
  width: 220px;
  height: 360px;
}
</style>
