<template>
  <a-modal title="选择组织机构"
           :visible="shown"
           :confirm-loading="confirmLoading"
           :mask-closable="true"
           :width="600"
           :centered="true"
           wrap-class-name="data-center-popup-modal"
           :after-close="afterClosed"
           @cancel="closeModal">
    <a-input-search v-model="filterTxt"
                    :allow-clear="true"
                    placeholder="请输入组织机构名称"
                    enter-button="搜 索"
                    class="input-search"
                    @search="onSearch"/>

    <a-spin v-if="!options || !options.length" size="large" tip="加载中..." class="spin"></a-spin>

    <div class="tree-wrapper">
      <div v-if="!filteredOptions || !filteredOptions.length" class="empty mt-20">
        <span class="empty-text">未查询到组织机构</span>
      </div>

      <a-tree v-model="checkedKeys"
              :checkable="true"
              :check-strictly="true"
              :replace-fields="fieldMap"
              :auto-expand-parent="autoExpandParent"
              :default-expand-all="false"
              :expanded-keys="expandedKeys"
              :tree-data="filteredOptions"
              @expand="onExpand"
              @check="onCheck"/>
    </div>

    <div slot="footer" class="footer">
      <a-button class="btn cancel" @click="closeModal">取消</a-button>
      <a-button class="btn confirm" @click="confirmModal">确认</a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex';
import Common from '@/utils/Common';

export default {
  name: 'SelectOrgModal',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String, default: '' },
    sameLevel: { type: Boolean, default: false }, // 开启 只允许选择同一层级的元素，可以跨父级，用 level 字段判断
    alwaysShowSelected: { type: Boolean, default: true }, // 开启时，搜索结果 始终保留已选中项
  },
  computed: {
    ...mapState({
      options: state => state.Common.orgTreeDataCenter,
    }),
  },
  watch: {
    value() {
      this.copy();
      this.setDefault();
    },
    options: {
      immediate: true,
      handler(val) {
        if (!val?.length) {
          return;
        }
        this.copy();
      },
    },
  },
  data() {
    return {
      shown: false,
      confirmLoading: false,
      loading: true,

      filteredOptions: [],
      filterTxt: '',

      firstCheckedLevel: null,
      autoExpandParent: true,
      expandedKeys: [],
      checkedKeys: { checked: [], halfChecked: [] },
      fieldMap: { children: 'children', title: 'name', key: 'id' },
    };
  },
  created() {
    this.setDefault();
  },
  methods: {
    show() {
      this.setDefault();
      this.shown = true;
    },
    close() {
      this.shown = false;
    },
    afterClosed() {
      this.filterTxt = '';
      this.onSearch();
    },
    copy() {
      this.filteredOptions = JSON.parse(JSON.stringify(this.options));
    },
    onSearch() {
      this.copy();
      if (!this.filterTxt) {
        if (this.sameLevel) {
          this.setDefault();
        }
        return;
      }

      this.filterOptions(this.filteredOptions, this.filterTxt);
      this.expandAll(this.filteredOptions);
    },
    filterOptions(options, txt) {
      for (let i = options.length; i >= 0; i -= 1) {
        if (options[i]?.children?.length) {
          this.filterOptions(options[i].children, txt);
        }
        if (!options[i]?.children?.length && options[i]?.name.indexOf(txt) === -1) {
          if (!this.alwaysShowSelected || !this.checkedKeys.checked?.includes(options[i].id)) {
            options.splice(i, 1);
          }
        } else if (options[i] && this.sameLevel && this.firstCheckedLevel) {
          options[i].disabled = options[i]?.level !== this.firstCheckedLevel;
        }
      }
    },
    getSelectedItems(checkedKeys, options) {
      return Common.getSelectedItems(checkedKeys, options, { id: 'id', name: 'name' });
    },
    getCheckedKeysArr() {
      if (!this.checkedKeys) {
        return [];
      }
      if (this.checkedKeys.checked) {
        return this.checkedKeys.checked;
      }
      return this.checkedKeys || [];
    },
    confirmModal() {
      const data = this.getSelectedItems(this.getCheckedKeysArr(), this.filteredOptions);
      this.$emit('change', data.map((i) => i.id).join(','));
      this.$emit('changed', { data });
      this.close();
    },
    closeModal() {
      this.$emit('close');
      this.updateTreeEnabled([]);
      this.close();
    },
    setDefault() {
      if (!this.value) {
        this.checkedKeys = { checked: [], halfChecked: [] };
        this.setFirstCheckedLevel();
        this.updateTreeEnabled([]);
        return;
      }
      const ids = this.value.split(',');
      this.checkedKeys = { checked: ids.map(i => +i), halfChecked: [] };
      this.setFirstCheckedLevel();
      this.updateTreeEnabled(this.checkedKeys?.checked || []);

      this.expandedKeys = [...this.checkedKeys?.halfChecked];
    },
    setFirstCheckedLevel() {
      if (this.checkedKeys?.checked?.length) {
        const item = Common.getSelectedItems([this.checkedKeys.checked[0]], this.filteredOptions);
        this.firstCheckedLevel = item?.[0]?.level || undefined;
      }
      if (!this.checkedKeys?.checked?.length) {
        this.firstCheckedLevel = undefined;
      }
    },

    expandAll(options) {
      const ops = [...options];
      const expandedKeys = [];

      for (let i = 0; i < ops.length; i += 1) {
        expandedKeys.push(ops[i].id);

        if (ops[i].children?.length) {
          ops.push(...ops[i].children);
        }
      }

      this.expandedKeys = expandedKeys;
    },

    updateTreeEnabled(checkedKeys) {
      const options = [...this.filteredOptions];

      if (options && options.length) {
        options.forEach(item => this.updateItemEnabled(item, true, checkedKeys));
      }
      this.checkedKeys = {
        checked: [...this.checkedKeys?.checked || []],
        halfChecked: [...this.checkedKeys?.halfChecked || []],
      };
    },

    updateItemEnabled(item, enable, checkedKeys) {
      item.disabled = !enable;

      if (this.sameLevel && this.firstCheckedLevel && this.firstCheckedLevel !== item.level) {
        item.disabled = true;
      }

      if (item.disabled) {
        this.remove(this.checkedKeys?.checked, item.id);
      }

      if (enable && checkedKeys.indexOf(item.id) > -1) {
        enable = false;
      }

      if (item.children && item.children.length) {
        if (checkedKeys.includes(item.id)) {
          const ops = [...item.children];
          for (let i = 0; i < ops.length; i += 1) {
            ops[i].disabled = true;
            this.remove(checkedKeys, ops[i].id);

            if (ops[i].children?.length) {
              ops.push(...ops[i].children);
            }
          }
        } else {
          let half = false;
          item.children.forEach(i => {
            const res = this.updateItemEnabled(i, enable, checkedKeys);
            if (res.checked || res.halfChecked) {
              half = true;
              this.add(this.checkedKeys?.halfChecked, item.id);
            }
          });
          if (!half) {
            this.remove(this.checkedKeys?.halfChecked, item.id);
          }
        }
      }

      return {
        id: item.id,
        name: item.name,
        disabled: item.disabled,
        checked: this.checkedKeys?.checked?.includes(item.id),
        halfChecked: this.checkedKeys?.halfChecked?.includes(item.id),
      };
    },

    remove(arr, item) {
      if (!arr || !arr.length) {return;}
      const idx = arr.indexOf(item);
      if (idx > -1) {
        arr.splice(idx, 1);
      }
    },

    add(arr, item) {
      if (!arr) {return;}
      this.remove(arr, item);
      arr.push(item);
    },

    onExpand(expandedKeys) {
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.autoExpandParent = false;
      this.expandedKeys = expandedKeys;
    },
    onCheck(checkedKeys) {
      this.setFirstCheckedLevel();
      this.updateTreeEnabled(checkedKeys?.checked || []);
    },
    onselect(selectedKeys) {
      if (!selectedKeys || !selectedKeys.length) {
        return;
      }
      const expandedKeys = this.expandedKeys;
      selectedKeys.forEach(i => {
        const idx = expandedKeys.findIndex(e => e === i);
        if (idx > -1) {
          expandedKeys.splice(idx, 1);
        } else {
          expandedKeys.push(i);
        }
        this.expandedKeys = expandedKeys;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.input-search {
  margin-bottom: 8px;

  ::v-deep {
    .ant-input {
      height: 29px;
      background: #FFFFFF;
      border: 1px solid #D6DCEC;
      border-right: none;
      border-radius: 4px 0 0 4px;
    }

    .ant-input-group-addon {
      height: 29px;
    }

    .ant-btn {
      height: 29px;
      border-radius: 0 4px 4px 0;
      background: linear-gradient(90deg, #334FA0 0%, #3C85B8 100%);
    }
  }
}

.tree-wrapper,
.checkbox-group {
  overflow-y: auto;
  width: 100%;
  height: 50vh;
}

.ant-tree {
  ::v-deep {
    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
      background-color: #334FA0;
    }

    .ant-tree-checkbox-checked {
      &::after {
        border-color: #334FA0;
      }

      .ant-tree-checkbox-inner {
        background-color: #334FA0;
        border-color: #334FA0;
      }
    }
  }
}
</style>

<style lang="scss">
@import "../../scss/modal.scss";
</style>
