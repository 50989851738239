<template>
  <div class="select-typed-date">
    <SelectDateType v-model="type" class="date-type" @change="onTypeChange"/>

    <SelectYear v-if="type === 'year'" v-model="date" @changed="onDateChange"/>
    <SelectQuarter v-else-if="type === 'quarter'" v-model="date" @changed="onDateChange"/>
    <SelectMonth v-else-if="type === 'month'" v-model="date" @changed="onDateChange"/>
    <SelectWeek v-else-if="type === 'week'" v-model="date" @changed="onDateChange"/>
  </div>
</template>

<script>
import moment from 'moment';

import SelectDateType from './SelectDateType.vue';
import SelectYear from './SelectYear.vue';
import SelectQuarter from './SelectQuarter.vue';
import SelectMonth from './SelectMonth.vue';
import SelectWeek from './SelectWeek.vue';

export default {
  name: 'SelectTypedDate',
  components: {
    SelectDateType,
    SelectYear,
    SelectQuarter,
    SelectMonth,
    SelectWeek,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default: () => ({ type: 'year', date: '' }),
      validator: (val) => !val || ['year', 'quarter', 'month', 'week'].includes(val?.type),
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val, old) {
        if (val?.type === old?.type && val.date === old?.date) {
          return;
        }
        this.type = val.type;

        switch (val.type) {
          case 'year':
            this.date = [
              val.date ? moment(val.date || null, 'YYYY').startOf('years') : undefined,
              val.date ? moment(val.date || null, 'YYYY').endOf('years') : undefined,
            ];
            break;
          case 'quarter':
            this.date = [
              val.date ? moment(val.date || null, 'YYYY-Q').startOf('months') : undefined,
              val.date ? moment(val.date || null, 'YYYY-Q').endOf('months') : undefined,
            ];
            break;
          case 'month':
            this.date = [
              val.date ? moment(val.date || null, 'YYYY-MM').startOf('months') : undefined,
              val.date ? moment(val.date || null, 'YYYY-MM').endOf('months') : undefined,
            ];
            break;
          case 'week':
            this.date = [
              val.date ? moment(val.date || null, 'YYYY-ww').startOf('weeks') : undefined,
              val.date ? moment(val.date || null, 'YYYY-ww').endOf('weeks') : undefined,
            ];
            break;
          default:
        }
      },
    },
  },
  data() {
    return {
      type: 'year',
      date: [undefined, undefined],
    };
  },
  methods: {
    onTypeChange() {
      this.date = [undefined, undefined];
    },
    onDateChange(evt) {
      this.$emit('change', evt);
    },
  },
};
</script>

<style scoped lang="scss">
.select-typed-date {
  display: inline-block;
  height: 32px;
  > * {
    vertical-align: middle;
  }
}

.date-type {
  margin-right: 10px;
}
</style>
