<template>
  <a-range-picker
    v-model="dateRange"
    ref="rangePickerRef"
    :mode="mode"
    :placeholder="placeholder"
    :format="format"
    :allow-clear="allowClear"
    :get-calendar-container="getContainerStart"
    separator="至"
    class="select-range-month"
    :class="{ 'hide-suffix': !showSuffix }"
    @openChange="openChange"
    @panelChange="onPanelChange"
    @change="onChange"
  >
    <template slot="suffixIcon">
      <a-icon type="down" />
    </template>
  </a-range-picker>
</template>

<script>
import moment from 'moment';

const defaultDateTypes = ['date', 'month', 'year', 'decade'];

export default {
  name: 'SelectRangePicker',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      default: () => [undefined, undefined]
      // validator: (val) => !val || val.length === 2 && val.every(
      //   (i) => i === undefined || /[0-9]{4,4}-[0-9]{2,2}/g.test(i),
      // ),
    },
    showSuffix: { type: Boolean, default: true },
    allowClear: { type: Boolean, default: true },
    type: { type: String, default: 'month' },
    format: { type: String, default: 'YYYY-MM' },
    mode: { type: Array, default: () => ['month', 'month'] },
    placeholder: { type: Array, default: () => ['开始月', '结束月'] }
  },
  computed: {
    getContainerStart() {
      return () => this.$refs.rangePickerRef.$el.parentNode;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val, old) {
        if (
          val?.length === old?.length &&
          val.every((i, idx) => i === old[idx])
        ) {
          return;
        }
        this.dateRange = val?.map((i) =>
          i ? moment(i, this.format) : undefined
        ) || [undefined, undefined];
      }
    },
    dateRange: {
      immediate: false,
      handler(val) {
        const range = val.map((i) => i?.format(this.format)) || undefined;
        this.$emit('change', range?.length === 2 ? range : undefined);
      }
    }
  },
  data() {
    return {
      dateRange: [undefined, undefined]
    };
  },
  methods: {
    onChange(value) {
      this.dateRange = value;
    },
    openChange(status) {
      this.open = !!status;
    },
    onPanelChange(value, mode) {
      this.mode[0] = this.getMode(mode?.[0] || this.mode[0], this.type);
      this.mode[1] = this.getMode(mode?.[1] || this.mode[1], this.type);
      this.dateRange = value;

      if (!mode) {
        this.mode = 'year';
        this.open = false;
      }
    },
    getMode(mode, minMode) {
      return defaultDateTypes.indexOf(mode) <= defaultDateTypes.indexOf(minMode)
        ? minMode
        : mode;
    },
    //限制当天之前的日期不可选
    // disabledDate(current, type) {
    //   console.log(current, type, moment().subtract(1, 'days').endOf('day'),current > moment().subtract(1, 'days').endOf('month'));
    //   return current && current > moment().subtract(1, 'days').endOf('month');
    //   //return current && current < moment().subtract(0, 'days'); //当天之前的不可选，不包括当天

    //   //return current && current < moment().endOf(‘day');当天之前的不可选，包括当天
    // }
  }
};
</script>

<style scoped lang="scss">
@import '../../scss/select-date.scss';

.select-range-month {
  width: 242px;

  &.hide-suffix {
    width: 200px;
  }
}
</style>
